main {
  min-height: 80vh;
}
body {
  background-color: #f2f4f8;
  overflow-x: hidden;


  /* font-family: "Trebuchet MS"; */
  /* font-family: "Roboto", sans-serif !important; */
  /* font-family: "Libre Franklin", sans-serif !important; */
  /* font-family: "Montserrat", sans-serif !important; */
}


.container {
  max-width: 100%;
  padding: 0 10px; 
}


@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 900px;
  }
}
@media (min-width: 576px) {
  .filter_off {
      display: none !important;
  }
}
.toggle_ico{
  font-size: 32px;
  color: #000;
}
@media (max-width: 576px) {
  .filter_on {
    display: none !important;
}
}
.for_he{
  width: 320px !important;
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
    z-index: 10000;
  }
}
.color_black {
  color: #343a40 !important;
}
.btn-primary{
  background: radial-gradient(circle at 10% 20%, #e47600 0, #f7bd02 90%);
  padding: .5rem 1.5rem;
  border-radius: 6px;
}
.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: bold !important;
}
strong {
  font-weight: bold;

  color: #000;
}
@media (max-width: 768px) {
  strong {
    font-size: 14px;
  }
}
/* @font-face {
  font-family: "Trebuchet MS";
  src: url("./trebuc.ttf");
} */
@font-face {
  font-family: "Trebuchet MS";
  src: url("./Roboto-Black.ttf");
}
/* @font-face {
  font-family: "Trebuchet MS";
  src: url("./LibreFranklin-VariableFont_wght.ttf");
} */
/* @font-face {
  font-family: "Trebuchet MS";
  src: url("./Montserrat-VariableFont_wght.ttf");
} */
h3 {
  padding: 1rem 0;
}

h1 {
  font-size: 1.8rem;
  padding: 1rem 0;
}

h2 {
  font-size: 1.4rem;
  padding: 0.5rem 0;
  /* letter-spacing: 0; */
}
/* p {
  letter-spacing: 0; 
} */
.bg-success {
  background-color: #e57a00 !important;
}
.form-control {
  border: 1px solid #ced4da;
}

/* .rating span{
    margin: 0.1rem;
} */

/* carousel */
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
}

.carousel-caption {
  position: absolute;
  top: 0;
}

.carousel-caption h2 {
  color: #fff;
}

@media (min-width: 1200px) {
  .carousel .img {
    height: 515px !important;
  }
}
@media (max-width: 575.98px) {
  ul {
    padding-left: 35px !important;
  }
}
.form-control:focus {
  color: #55595c;
  background-color: unset;
  border-color: #5a5a5a;
  outline: 0;
  box-shadow: unset;
}
@media (max-width: 1199.98px) {
  .mdl-compare {
    margin-top: 2rem;
  }
}

.carousel a {
  margin: 0 auto;
}

.card_custom {
  box-shadow: 0 1px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background-color: rgb(255, 255, 255);
  border-radius: 12px;
  font-weight: bold;
  border: unset;
  /* width: 130px !important; */
  height: 106px !important;
  cursor: pointer;
  transition: all 0.2s ease-out;
  display: flex;
  align-items: center;
}

.card_custom:hover {
  width: 100%;
  color: #fc8f27;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  transition: all 0.2s ease-out;
}
.card_custom img {
  padding: 0.5rem 1rem;
  width: 90px;
}
.card_custom span {
  font-size: 12px;
}

.card_custom h3{
  margin-top: -20px;
}

/* NavBar */
.nav_ber {
  display: flex;
}
/* .full_nav {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    background-color: #fff;
} */

.full_nav {
  position: fixed;
  top: 87px;
  right: 0;
  left: 0;
  z-index: 1030;
  margin-bottom: 30px;
  transition: all 0.1s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  background-color: #fff;
}

.full_nav.scrolled {
  position: fixed;
  top: 0;
  transition: all 0.1s ease-in-out;
}
@media (max-width: 991.98px) {
  .nav_ber {
    display: none;
  }
}
.nav_bb {
  padding: 0.25rem 1rem !important;
  margin-bottom: 45px;
}
@media (max-width: 575.98px) {
  .nav_bb {
    margin-bottom: 10px;
  }
}
.nav_bb .dropdown-menu {
  position: static;
  float: none;
  background-color: #fff;
  /* color: #fff; */
  font-weight: bold;
}
.width_nav_fixed {
  width: 40% !important;
}
.nav_bb .dropdown-item {
  padding: 10px;
  color: #fff;
  position: relative;
  /* color: #343a40; */
  font-weight: bold;
  text-transform: capitalize;
  font-size: 16px;
}
@media (max-width: 575.98px) {
  .nav_responsive {
    align-items: start !important;
    padding: 0.5rem !important;
  }
  .nav_responsive_padding {
    margin-bottom: 0.5rem !important;
  }
  .nav_responsive_padding a {
    margin-bottom: 0.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .nav_bb .dropdown-item {
    padding: 10px;
    color: #000 !important;
    position: relative;
    /* color: #343a40; */
    font-weight: bold;
    text-transform: capitalize;
    font-size: 16px;
  }
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.09375rem;
    line-height: 1;
    background-color: #fc8f27 !important;
    border: 1px solid #919aa1 !important;
    border-radius: 6px;
  }
}
.nav_bb .dropdown-item:hover {
  background-color: #fc8f27 !important;
  /* color: #fff !important; */
}
.nav_ber .dropdown-menu {
  position: static;
  float: none;
  background-color: #fff;
  /* color: #fff; */
  font-weight: bold;
  padding: 0;
}
.nav_ber .dropdown-menu .dropdown-item {
  /* color: #fff !important; */
  position: relative;
  color: #343a40;
  font-weight: bold;
  text-transform: capitalize;
  font-size: 14px;
}

.nav_text .dropdown-menu {
  position: static;
  float: none;
  background-color: #fff;
  /* color: #fff; */
  font-weight: bold;
  padding: 0;
}
.sub_navLink .dropdown-item {
  color: #000 !important;
  background-color: #fff;
  font-weight: 500;
  font-size: 13px;

}
.nav_link .dropdown-item:hover {
  background-color: transparent;
  color: #fff !important;
}
.sub_navLink .dropdown-item:hover {
  background-color: #fc8f27 !important;
}
.nav_formcontrol {
  width: 400px;
}
.nav_serch_form {
  border: 1px solid #ea8b01 !important;
  border-radius: 4px !important;
  background-color: #fff;
  margin: 0.25rem 0 !important;
}
.align_for {
  align-items: center;
}
@media (max-width: 575.98px) {
  .align_for {
    align-items: start !important;
  }
}
@media (max-width: 575.98px) {
  .nav_serch_form {
    width: 76%;
  }
}
@media (max-width: 320px) {
  .nav_serch_form {
    width: 74% !important;
  }
}

.nav_serch_btn {
  border-radius: 4px !important;
  background-color: #000000 !important;
  margin: 0.15rem -6px;
  /* border: 2px solid black; */
}
.nav_serch_btn i {
  color: #fc8f27;
  font-size: 20px;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}
.admin_ico {
  color: #fc8f27;
  font-size: 18px;
}
.admin_ico_cart {
  font-size: 18px;
}
.nav_link {
  padding: 0.25rem 0.20rem !important;
  position: relative;
  
}
.nav_link .dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: red;
}
.nav_web_link{
  color: #fff;
}
.nav_web_link_sub{
  color: #fff;
  background: radial-gradient(circle at 10% 20%, rgb(228, 118, 0) 0%, rgb(247, 189, 2) 90%);
  padding: .5rem 1rem 0.5rem 1rem;

}
.nav_web_link:hover{

  color: #fff !important;
}
.card_helpline h6 {
  color: #fff;
  margin-top: 0.5rem;
  /* letter-spacing: 1px; */
}

/* Footer */
.footer_body {
  background-color: #000;
  padding-top: 50px;
  color: #fff;
}
.footer_body h4 {
  color: #fff;
  margin-bottom: 30px;
  font-size: 14px;
  font-weight: normal;
  text-transform: uppercase;
  /* letter-spacing: 4px; */
}
.footer_body b {
  color: #fff;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: normal;
}
.footer_body address {
  color: #bababa;
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 0px;
}
.footer_body .highlight_color {
  color: #fc8f27;
}
footer ul {
  margin: 0;
  padding: 0;
}
footer ul li {
  display: inline-block;
  width: 32%;
  line-height: 20px;
  margin: 0;
  padding: 0 0 14px;
}
footer ul li a {
  font-size: 15px;
  color: #bababa;
  white-space: nowrap;
}
@media (max-width: 768px) {
  footer ul li {
    width: auto;
    padding: 0 10px;
    margin-bottom: 10px;
    position: relative;
  }
}
@media (max-width: 768px) {
  footer ul {
    display: block;
    padding-bottom: 20px;
  }
}

.footer-big-btn {
  display: block;
  padding: 10px 0;
  border-radius: 50px;
  cursor: pointer;
  margin-bottom: 20px;
  border: 1px solid #fc8f27;
  transition: all 0.2s ease-out;
}
.footer-big-btn:hover {
  transition: all 0.2s ease-out;
  border: 1px solid #fc8f27;
}
.footer-big-btn .ic {
  color: #fff;
  float: left;
  font-size: 36px;
  line-height: 40px;
  padding: 0 10px 0 20px;
  margin-right: 20px;
  border-right: 1px solid #333;
}

.footer-big-btn .ic i {
  font-size: 22px;
}
.footer-big-btn p {
  margin: 0 0 4px;
  font-size: 12px;
  line-height: 16px;
  color: #bababa;
}
.footer-big-btn h5 {
  color: #fc8f27;
  margin: 0;
  font-size: 13px;
  line-height: 28px;
  font-weight: normal;
}
.social-footer {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 15px 0;
  display: flex;
}
@media (max-width: 768px) {
  footer .social-footer {
    display: block !important;
    justify-content: left !important;

    margin-bottom: 10px !important;
  }
}
@media (max-width: 768px) {
  .app-links {
    display: block !important;
  }
}
@media (max-width: 768px) {
  footer .social-links {
    text-align: left !important;
    margin-top: 10px;
  }
}
.social-footer .app-links {
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
  margin: 0;
  display: flex;
  align-items: center;
  flex: 1 1 auto;
}
footer .social-links {
  flex: 0 0 auto;
  padding: 0;
  /* margin: 0; */
  text-align: right;
}
footer .social-links a {
  margin: 0 0 0 6px;
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;

  padding: 4px;
  background: #fff;
}
.f-icon {
  width: 20px;
  height: 20px;
}
.icon-sprite {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url(https://www.startech.com.bd/catalog/view/theme/starship/images/xicon-sprite-v2.png.pagespeed.ic.4KZ1094RMo.png)
    no-repeat 0 0;
}
.social-footer .app-link {
  display: flex;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 7px;
  padding: 6px 10px 6px 6px;
  margin-left: 20px;
}
@media (max-width: 768px) {
  .social-footer .app-link {
    margin-left: 0px;
  }
}
.social-footer a.app-link .icon-sprite {
  margin-right: 5px;
}
.icon-sprite.playstore {
  background-position: -120px 0;
}
.icon-sprite {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url(https://www.startech.com.bd/catalog/view/theme/starship/images/xicon-sprite-v2.png.pagespeed.ic.4KZ1094RMo.png)
    no-repeat 0 0;
}
.social-footer .app-link-text span.download {
  font-size: 10px;
  line-height: 1;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 2px;
}
.social-footer .app-link-text span.app-store {
  font-size: 14px;
  line-height: 1;
  color: #fff;
}
.social-footer .app-links {
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
  margin: 0;
  display: flex;
  align-items: center;
  flex: 1 1 auto;
}
.social-footer .app-link-items {
  display: flex;
}
.social-footer .app-link-text span {
  display: block;
}

.sub-footer {
  padding: 15px 0 25px;
}
.sub-footer p {
  font-size: 12px;
  margin: 0;
  color: #666;
}
.sub-footer .powe#fc8f27-by {
  text-align: right;
}
@media (max-width: 768px) {
  .sub-footer .powe#fc8f27-by {
    text-align: left;
  }
}
@media (max-width: 768px) {
  .app-link-text {
    margin: 10px 0;
  }
  .width_100 {
    width: 100%;
  }
}

@media (max-width: 991.98px) {
  .nav_bb .submenu {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 10rem;
    background-color: #fff;
    /* border: 1px solid rgba(0, 0, 0, 0.15); */
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    display: none;
    transform: translate3d(0px, 1.5px, 0px) !important;
    box-shadow: 0 1px 3px -2px rgba(0, 0, 0, 0.12),
      0 1px 2px rgba(0, 0, 0, 0.24);
    z-index: 99;
  }
}

@media (max-width: 991.98px) {
  .nav_bb .submenuLast {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 10rem;
    background-color: #fff;
    /* border: 1px solid rgba(0, 0, 0, 0.15); */
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    display: none;
    transform: translate3d(0px, 1.5px, 0px) !important;
    box-shadow: 0 1px 3px -2px rgba(0, 0, 0, 0.12),
      0 1px 2px rgba(0, 0, 0, 0.24);
    z-index: 99;
  }
}
.dd_ii .dropdown-menu {
  transform: translate3d(150px, -30.5px, 0px) !important;
}
.submenu_icon {
  font-size: 12px;
  color: #fff;
 
  margin-left: 5px;
    margin-top: 4px;
    transform: rotate(90deg);
}
@media (max-width: 575.98px) { 
  .submenu_icon {
    font-size: 12px;
    color: #000;
   
    margin-left: 5px;
      margin-top: 4px;
      transform: rotate(90deg);
  }

 }
.submenu_icon_sm {
  font-size: 12px;
  color: #000;
 
  margin-left: 5px;
    margin-top: 4px;
    transform: rotate(90deg);
}
.dropdown-item {
  position: relative;
  color: #000;

  text-transform: capitalize;
  font-size: 14px;
  padding: 0.25rem 1rem;
  margin-bottom: -1px;
}

.dropdown-item:hover .submenu {
  display: block;
}
.dropdown-item:hover .submenuLast {
  display: block;
}

.submenu {
  position: absolute;
  top: 100%;
  left: 100%;

  min-width: 10rem;
  /* background-color: #343a40; */
  /* border: 1px solid rgba(0, 0, 0, 0.15); */
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  display: none;
  transform: translate3d(0px, -28.5px, 0px) !important;
  box-shadow: 0 1px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.submenu1 {
  position: absolute;
  top: 100%;
  left: 100%;

  min-width: 10rem;
  /* background-color: #343a40; */
  /* border: 1px solid rgba(0, 0, 0, 0.15); */
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  display: none;
  transform: translate3d(0px, -28.5px, 0px) !important;
  box-shadow: 0 1px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.submenuLast {
  position: absolute;
  top: 100%;
  right: 100%;

  min-width: 10rem;
  /* background-color: #343a40; */
  /* border: 1px solid rgba(0, 0, 0, 0.15); */
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  display: none;
  transform: translate3d(0px, -28.5px, 0px) !important;
  box-shadow: 0 1px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.submenu.show {
  display: block;
}
.submenu1.show {
  display: block;
  position: absolute;
  top: 100%;
  left: 100%;

  min-width: 10rem;
  /* background-color: #343a40; */
  /* border: 1px solid rgba(0, 0, 0, 0.15); */
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;

  transform: translate3d(0px, -28.5px, 0px) !important;
  box-shadow: 0 1px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.submenuLast.show {
  display: block;
}

@media (max-width: 575.98px) { .site_description p {
  text-align: justify;
 } }
/* Product page css */
.card_product {
  box-shadow: 0 1px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background-color: rgb(255, 255, 255) !important;

  border: unset !important;
  height: 425px !important;
}

.card_head_custom {
  background-color: #fff;
  position: relative;
  display: flex;
  margin: auto;
}

@media (max-width: 575.98px) { .card_head_custom img {
  height: 250px;
  width: 250px;
} }
.offer {
  position: absolute;
  background-color: #fc8f27;
  color: #fff;
  padding: 0px 20px;
  border-radius: 0px 6px 6px 0px;
  top: 10%;
  left: 0;
}
.quick-view-button {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #343a40;
  color: #fff;
  padding: 5px 10px;
  border: none;
  transition: opacity 0.3s;
  transition: all 0.3s ease-out;
  cursor: pointer;
  opacity: 0;
  text-align: center;
}

.product_card:hover .quick-view-button {
  opacity: 1;
  transition: opacity 0.3s;
  transition: all 1s ease-out;
}
.quick-view-button-heart {
  position: absolute;
  top: 10px;
  right: 0;
  transform: translateX(-50%);
  font-size: 16px;
  color: #828282;
  padding: 0px 5px;
  border: 1px solid #828282;
  border-radius: 50%;
  transition: opacity 0.3s;
  transition: all 0.3s ease-out;
  cursor: pointer;
  opacity: 0;
  text-align: center;
}
.quick-view-button-heart :hover {
  color: #cf2e2e;
}
.product_card:hover .quick-view-button-heart {
  opacity: 1;
  transition: opacity 0.3s;
  transition: all 0.3s ease-out;
}

.product_card:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  transition: all 0.2s ease-out;
}

.product_name {
  color: #000;
  font-size: 15px;
  line-height: 24px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 52px;
  cursor: pointer;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Display 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
@media (max-width: 575.98px) {
  .product_name {
    color: #000;
    font-size: 12px;
  }
   }
.product_namee {
  color: #000;
  font-size: 15px;
  line-height: 24px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 55px;

  display: -webkit-box;
  -webkit-line-clamp: 2; /* Display 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
.product_name:hover {
  text-decoration: underline;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.product_price {
  font-size: 18px;
  line-height: 1;
  color: green;
}
@media (max-width: 575.98px) {
  .product_price {
   
    font-size: 12px;
  }
   }
.product_code {
  font-size: 18px;
  line-height: 1;
  color: rgb(228, 118, 0);
}
.product_table .t_name {
  padding: 0 30px;
  width:25%;
  background: #e0e0e07a;
  text-align: right;
  color: #464444;
  font-size: 15px;
  line-height: 40px;
  font-weight: bold;
}
.product_table .heading_row {
  background: #bfbaba;
  padding: 8px 20px;
  font-size: 12px;
  color: #ffffff;
  font-weight: bold;
}
.bg_white{
  background-color: #fff !important;


}
.for_height{
  /* height: 96vh !important; */
  background-color: #fff;
}
@media (min-width: 1400px) {}

.form_c{
  display: block;
    width: 100%;
    height: unset;
    padding: unset;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #55595c;
    background-color: unset;
    background-clip: padding-box;
    border: 0 solid #ced4da;
    border-radius: 0;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.table td {
  vertical-align: middle;
}
.list-group-item {
  border: unset;
}

.nav_tabs .nav-link {
  border-top: 2px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  background-color: rgba(0, 0, 0, 0.04);
  padding-left: 15px;
  padding-right: 15px;
  text-transform: uppercase;
  font-size: 14px;
  margin-right: unset;
  font-weight: bold;
}
/* .nav_cart{
    padding-top: 0.25rem !important;
    padding-bottom: 0 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    font-weight: normal;
    font-size: 15px;
    text-transform: capitalize;
} */
.cart_span {
  font-size: 11px;

  margin-left: 0.5rem;
  white-space: nowrap;
  color: rgba(255, 255, 255, 0.5);
  text-transform: capitalize;
}
.nav_btn {
  background: linear-gradient(
    45deg,
    #0f5828,
    #fc8f27,
    #0bc1e9,
    #fc8f27,
    #fc8f27
  );
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  border: none;

  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 4px;
  outline: none;
  text-align: center;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.nav_tabs .nav-link.active {
  border-top-color: #ee9c01;
  background-color: #fff;
}
.padding_all {
  padding: 1rem;
  margin: 0;
}
@media (max-width: 575.98px) {
  .padding_all {
    padding: 0;
    margin-bottom: 0.5rem;
    width: 100%;
  }
  .marginTop {
    margin-top: 0.5rem;
    width: 100%;
  }
  .nav_btn {
    margin-top: 0.5rem;
    width: 100%;
  }
  .width_nav_fixed {
    margin-top: 30px;
    width: 100% !important;
  }
}
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: #000;
}
.line_height {
  line-height: 0.4 !important;
}
#username {
  border: unset !important;
  padding: unset !important;
}
#adminmenu {
  border: unset !important;
  padding: unset !important;
}
.product_img {
  width: 50px;
}
.prod_price {
  color: Black;
  font-weight: bold;
}
.prod_price_view {
  color: Black;
  font-weight: 400;
}
.prod_price_cart {
  color: #fc8f27;
  font-size: 18px;

  font-weight: bold;
  padding: 0.5rem;
  /* border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd; */
  margin-bottom: 2rem;
}
.nav_prods {
  font-size: 16px;
}

.color_dark {
  background-color: #343a40;
}
.discount_info1 {
  color: #8d9195;
  font-size: 12px;
  line-height: 22px;
  font-weight: bold;
}
.taxtotall {
  color: #3a4856;
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
}
.cate_title {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}
.cat_card span {
  font-size: 16px;
  padding-top: 6px;
}
.cat_card {
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  border: unset;
  box-shadow: 0px 9px 30px 0px rgb(0 0 0 / 7%);
}
.cat_bag {
  background: #eceeef;
  padding: 10px;
  border-radius: 5px;
  border: unset;
  margin-bottom: 1rem;
}
.colr {
  color: red;
}
.cat_head {
  /* letter-spacing: 0; */
  font-weight: bold;
}
.cat_type {
  color: #fc8f27;
  /* letter-spacing: 0; */
}

.carousel .thumb.selected,
.carousel .thumb:hover {
  border: 1px solid #343a40 !important;
}
.product_btn {
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: #000;
  border: 0 solid transparent;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  border-radius: 6px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (max-width: 575.98px) {
  .product_btn {
   
    font-size: 12px;
  }
   }
a:hover {
  color: #55595c;
  text-decoration: none;
}
/* Cart Page */
.cart_img img {
  width: 100px;
}
.cart_img .select_form {
  border: 1px solid #ced4da !important;
  width: unset !important;
}
.select_form {
  width: unset !important;
}
.color_btn {
  border: 2px solid #343a40 !important;
  border-radius: 4px;
  margin-left: 1rem;
  padding: 0.5rem 1rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.color_btn:hover {
  border: 2px solid #343a40 !important;
  color: #fff;
  background-color: #000;
  border-radius: 4px;
  margin-left: 1rem;
  padding: 0.5rem 1rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.bg_cart {
  border-radius: 4px;
  background-color: #eceeef;
}
.cart_para {
  /* letter-spacing: 0px; */
}
.prices_cart h4 {
  /* letter-spacing: 0px; */
}
.cart_de {
  border-bottom: 1px solid #ced4da;
}
.p_colr {
  color: #000 !important;
  font-weight: bold;
}
.p_colr_gn {
  color: #fc8f27 !important;
  font-weight: bold;
}
/* hp wrap */

.hwrap {
  overflow: hidden; /* HIDE SCROLL BAR */
  background: #ced4da;
}
.noresult_found {
  /* letter-spacing: .5px; */
  font-weight: bold;
  color: #f7bd02 !important;
}
/* (B) MOVING TICKER WRAPPER */
.hmove {
  display: flex;
}

/* (C) ITEMS - INTO A LONG HORIZONTAL ROW */
.hitem {
  flex-shrink: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  text-align: center;
  color: #000;
}

/* (D) ANIMATION - MOVE ITEMS FROM RIGHT TO LEFT */
/* 4 ITEMS -400%, CHANGE THIS IF YOU ADD/REMOVE ITEMS */
@keyframes tickerh {
  0% {
    transform: translate3d(100%, 0, 0);
  }
  100% {
    transform: translate3d(-400%, 0, 0);
  }
}
.hmove {
  animation: tickerh linear 60s infinite;
}
.hmove:hover {
  animation-play-state: paused;
}
@media only screen and (max-width: 768px) {
  .hmove {
    animation-duration: 20s;
  }
}

.custom_modal {
  /* transform: translateX(100%) !important; */
  width: 300px !important;
  height: 100vh !important;
  margin: 0 !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  position: fixed !important;
  z-index: 1050 !important;
  background-color: #fff;
  overflow-y: scroll !important;
}
@media (max-width: 575.98px) { 
.custom_modal{
  width: 334px !important;
}
 }
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: unset;
  outline: 0;
  /* height: 80vh !important; */
}

/* 
Compare section */
.mdl-compare {
  background: #e6e6e6;
  margin-bottom: 30px;
  padding: 25px 30px;
  box-shadow: 5px 5px 0 #f0ad4e;
  border: 1px solid #f7941d;
}
.mdl-compare h4 {
  color: #000;
  text-align: center;
  margin-bottom: 2px;
}
.mdl-compare p {
  color: #000;
  text-align: center;
  margin: 0 0 14px;
  font-size: 14px;
  opacity: 0.5;
}
/* input, select, textarea {
    font-size: 15px;
    height: 42px;
    padding: 5px 15px;
    background: #fff;
    outline: none;
    width: 100%;
    font-family: "Trebuchet MS",sans-serif;
    border: 1px solid #ddd;
    border-radius: 4px;
} */
.cmpr-field {
  position: relative;
}
.cmpr-field select,
.cmpr-field input {
  border: none;
  padding: 5px 30px 5px 15px;
  margin-bottom: 15px;
  box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.05);
  text-overflow: ellipsis;
}
.cmpr-field select,
.cmpr-field input {
  border: none;
  padding: 5px 30px 5px 15px;
  margin-bottom: 15px;
  box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.05);
  text-overflow: ellipsis;
}
@media screen and (max-width: 991px) {
  .cmpr-table tr td.compare-blurb {
    display: block;
  }
}
@media screen and (max-width: 991px) {
  .cmpr-table tr td:first-child {
    flex: 0 0 100%;
    text-align: center;
    padding: 5px;
    background: #f5f7f9;
    border: none;
    font-size: 12px;
    font-weight: bold;
  }
}
@media screen and (max-width: 991px) {
  .cmpr-table tr td h4 {
    margin-top: 10px;
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 991px) {
  .cmpr-table.count-3 tbody td {
    flex: 0 0 50%;
  }
}
@media screen and (max-width: 991px) {
  .cmpr-table tr td:nth-child(3) {
    border-right: none;
  }
}
@media screen and (max-width: 991px) {
  .cmpr-header tr td:first-child {
    display: block;
  }
}

@media screen and (max-width: 991px) {
  .on-scroll .cmpr-table tr.cmpr-header {
    margin-right: 0;
    z-index: 5;
    max-width: calc(100% - 34px);
  }
}
@media screen and (max-width: 991px) {
  .cmpr-table tr td:first-child {
    flex: 0 0 100%;
    text-align: center;
    padding: 5px;
    background: #f5f7f9;
    border: none;
    font-size: 12px;
    font-weight: bold;
  }
}

.st-outline {
  background: transparent;
  display: inline-block;
  border: 2px solid #f18e18;
  padding: 0 20px;
  margin: 0;
  height: 42px;
  line-height: 38px;
  font-size: 14px;
  font-weight: bold;
  color: #f18e18;
  cursor: pointer;
  border-radius: 4px;
  outline: none;
  text-align: center;
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  transition: all 300ms linear;
}

@media (max-width: 575.98px) {
  .st-outline {
    height: 50px;
    line-height: 21px;
  }
}
.mdl-compare button.st-outline {
  width: 100%;
}
/* compare screen  */
.cmpr-table {
  border-top: 2px solid #e2e4e8;
  margin: 30px 0 70px;
}
.cmpr-table tr {
  display: flex;
  margin-bottom: -2px;
}
.cmpr-table tr td:first-child {
  color: #666;
  padding-left: 30px;
}
.cmpr-table tr td h4 {
  color: #000;
  font-size: 20px;
  padding: 10px 0;
}

.cmpr-table.count-4 tbody td {
  flex: 0 0 25%;
}
@media (max-width: 575.98px) {
  .cmpr-table.count-4 tbody td {
    flex: 0 0 36%;
  }
}
.cmpr-table tr td {
  flex: 0 0 20%;
  border-right: 1px solid #ecedef;
  border-bottom: 1px solid #ecedef;
  padding: 12px 15px;
  line-height: 20px;
}
.cmpr-table .compare-item-wrapper {
  text-align: center;
}
.cmpr-field {
  position: relative;
}

.pd-full section {
  background: #fff;
  padding: 20px;
  margin-bottom: 20px;
  margin-top: 0;
  border-radius: 4px;
  box-shadow: 0 1px 1px #fc8f27;
}
.product-details .q-n-r-section .section-head {
  display: flex;
  border-bottom: 1px solid #eee;
}
.product-details .section-head {
  padding-bottom: 20px;
}
.product-details .q-n-r-section .section-head > div {
  flex: 1 1 auto;
}
.product-details .q-n-r-section .section-head > div.q-action {
  flex: 0 0 auto;
  text-align: right;
  display: flex;
  align-items: center;
}
.empty-content {
  padding: 50px 0;
  text-align: center;
  width: 100%;
}
.empty-content .icon {
  padding: 20px;
  background: #fc8f272e;
  color: #fc8f27;
  border-radius: 50px;
  font-size: 48px;
  width: 88px;
  height: 88px;
}
.empty-content .empty-text {
  color: #666;
  padding-top: 20px;
  font-size: 14px;
}
.col-inner {
  padding: 1px 1px 0px 1px;
  /* background-color: rgb(245, 248, 250); */
  border-radius: 4px;
  width: 50%;
}
@media (max-width: 575.98px) {
  .col-inner {
    width: 100%;
  }
}
.customWidth {
  width: 25%;
  border: 1px solid rgb(228, 118, 0);
  border-radius: 6px;
}
@media (max-width: 575.98px) {
  .customWidth {
    width: 38%;
    font-size: 8px;
  }
}

.icon-box-left .icon-box-img,
.icon-box-right .icon-box-img {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-bottom: 0;
  max-width: 200px;
}
.icon-box .icon-box-img {
  margin-bottom: 1em;
  max-width: 100%;
  position: relative;
}
.progress {
  display: flex;
  height: 1rem;
  overflow: visible;
  line-height: 0;
  font-size: 0.65625rem;
  background-color: #f7f7f9;
}

@media (max-width: 575.98px) {
  .progress {
    display: block;
    margin-top: 0 !important;
  }
}
@media (max-width: 575.98px) {
  .manual_height {
    height: 170px;
  }
}
@media (max-width: 575.98px) {
  .display_small {
    display: block !important;
  }
}

@media (max-width: 575.98px) {
  .for_sm-device {
    display: none;
  }
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  font-size: 20px;
  padding: 18px;
  height: 100% !important;
  text-align: center;
  white-space: nowrap;
  margin: 2px;
  border-radius: 4px;

  transition: width 0.6s ease;
}
.card_checkout {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: unset;
  border-radius: 6px;
  transition: all 0.1s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

@media (max-width: 575.98px) {
  .card_checkout {
    /* margin-top: 10rem; */
  }
}
.form_control {
  border-radius: 4px;
}
.emi-terms .emi .emi-content {
  position: relative;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #ececec;
  box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
  padding: 20px;
  height: 100%;
}
.emi-terms .emi .emi-content .status {
  position: absolute;
}
.emi-terms .emi {
  margin-bottom: 1.5rem;
}
.emi-terms .emi .emi-content .status .status-online {
  width: 10px;
  height: 10px;
  background-color: #23e88e;
  border-radius: 50%;
  margin-right: 2px;
  display: inline-block;
}
.emi-terms .emi .emi-content .status .status-offline {
  width: 10px;
  height: 10px;
  background-color: #bdbdbd;
  border-radius: 50%;
  display: inline-block;
}
.emi-terms .emi .emi-content h2 {
  margin: 0;
  padding: 15px 0 5px 0;
  font-size: 20px;
  font-weight: normal;
  color: #01132d;
  line-height: 35px;
  /* letter-spacing: 0; */
}
.emi-terms .emi .emi-content h4 {
  font-size: 14px;
  color: #23262e;
  line-height: 22px;
  margin-top: 10px;
  font-weight: normal;
  /* letter-spacing: 0px; */
}
.emi-terms .emi .emi-content span {
  font-weight: bold;
}
.emi-tnc h1,
.saadiq-tnc h1 {
  margin-top: 30px;
  margin-bottom: 5px;
  font-size: 18px;
  color: #01132d;
  line-height: 35px;
  /* letter-spacing: 0px; */
}
.emi-tnc h1,
.saadiq-tnc h1 {
  margin-top: 30px;
  margin-bottom: 5px;
  font-size: 18px;
  color: #01132d;
  text-align: left;
  line-height: 35px;
}
.info-page ul li {
  margin: 0 0 4px 16px;
}
.emi-tnc li,
.saadiq-tnc li {
  color: #01132d;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 25px;
  text-align: left;
  list-style: circle;
  /* letter-spacing: 0; */
}
.emi-tnc h1 {
  margin-top: 30px;
  margin-bottom: 5px;
  font-size: 18px !important;
  color: #01132d;
  line-height: 35px;
  /* letter-spacing: 0; */
}
.saadiq-tnc h4 {
  font-size: 16px;
  font-weight: normal;
  line-height: 27px;
  /* letter-spacing: 0; */
}
.saadiq-tnc a {
  text-transform: capitalize;
}
.abt h1 {
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: bold;
  color: #01132d;
  line-height: 35px;
  /* letter-spacing: 0; */
}
.abt h2 {
  font-size: 18px;
  color: #01132d;
  line-height: 30px;
  margin-top: 25px;
  /* letter-spacing: 0; */
}
.abt p {
  margin-bottom: 10px;
  font-size: 15px;
  color: #01132d;
  line-height: 26px;
  /* letter-spacing: 0; */
}
.topper {
  background-color: #fff;
  padding: 3rem;
  border-radius: 6px;
}
@media (max-width: 575.98px) {
  .topper {
    padding: 1rem;
  }
}
.topper ul {
  margin-top: 20px;
  margin-bottom: 30px;
}
.topper ul li {
  color: #01132d;
  margin-bottom: 10px;
  line-height: 24.5px;
  font-size: 14px;
  list-style-type: circle;
}
.topper .nb {
  text-align: center;
  border-top: 1px solid #d6d6d6;
  color: #01132d;
  padding-top: 20px;
  margin-top: 48px;
}
.nb h3 {
  font-size: 18px;
  margin-top: 12px;
  line-height: 30px;
  /* letter-spacing: 0; */
}
/* .topper .nb a {
  padding: 2px 20px;
  margin-top: 7px;
} */
.odc ul {
  margin-top: 20px;
  margin-bottom: 30px;
}
.odc ul li {
  color: #01132d;
  margin-bottom: 10px;
  line-height: 24.5px;
  font-size: 14px;
  list-style-type: circle;
  /* letter-spacing: 0; */
}
.odc .nb {
  text-align: center;
  border-top: 1px solid #d6d6d6;
  color: #01132d;
  padding-top: 20px;
  margin-top: 48px;
}
.nb h3 {
  font-size: 18px;
  margin-top: 12px;
  line-height: 30px;
  /* letter-spacing: 0; */
}
.odc .nb a {
  padding: 2px 20px;
  margin-top: 7px;
}
.priv-pol .pp-desc {
  margin-top: 20px;
}
.priv-pol .nb {
  text-align: center;
  border-top: 1px solid #d6d6d6;
  color: #01132d;
  margin-top: 48px;
}
.priv-pol .nb h3 {
  font-size: 18px;
  margin-top: 20px;
  line-height: 30px;
  /* letter-spacing: 0; */
}
.priv-pol .nb p {
  margin-bottom: 0px;
  /* letter-spacing: 0; */
}
.priv-pol ul li {
  color: #01132d;
  margin-bottom: 0px;
  line-height: 24.5px;
  font-size: 14px;
  list-style-type: circle;
  /* letter-spacing: 0; */
}
.tnc .tnc-desc {
  margin-bottom: 30px;
}
.tnc ul li {
  color: #01132d;
  margin-bottom: 10px;
  line-height: 24.5px;
  font-size: 14px;
  list-style-type: circle;
  /* letter-spacing: 0; */
}
.tnc ul {
  margin-top: 20px;
  margin-bottom: 30px;
}
.tnc .nb {
  text-align: center;
  color: #01132d;
}
.tnc .nb h3 {
  font-size: 16px;
  margin-top: 20px;
  line-height: 25px;
  /* letter-spacing: 0; */
}
.tnc .nb a {
  line-height: 25px;
}

.contact-us-content {
  max-width: 950px;
  margin: 10px auto;
}
.contact-us-content i {
  font-size: 16px;
}
.each-contact-text h3 {
  /* letter-spacing: 0; */
  padding: 0;
  font-size: 18px;
  text-transform: capitalize;
}
.page-heading {
  /* letter-spacing: 0; */
  padding: 0;
  font-size: 16px;
}
.m-b-30 {
  margin-bottom: 30px;
}
.c-card {
  display: flex;
  padding: 20px;
  align-items: center;
}
.ws-box {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.c-card .ic {
  background: #fc8f27;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  text-align: center;
  line-height: 50px;
  margin-right: 20px;
}
.c-card p {
  margin: 0;
  font-size: 18px;
  line-height: 24px;
  /* letter-spacing: 0; */
}
.top-bar {
  padding: 10px 10px 10px 20px;
  margin-bottom: 10px;
  background-color: #f1f1f1;
}
.showroom {
  display: flex;
  flex-direction: column;
}
.showroom .address {
  padding-bottom: 10px;
  /* letter-spacing: 0; */
}
.showroom .address-inner {
  display: flex;
  padding: 15px 20px;
  align-items: center;
  text-transform: capitalize;
}
.showroom .address .address-details {
  flex: 1 1 400px;
  max-width: 400px;
  padding-right: 30px;
  text-transform: capitalize;
}
.showroom .address .address-line {
  font-size: 14px;
  line-height: 20px;
  color: #666;
  /* letter-spacing: 0; */
}
.showroom .address .phone-numbers {
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
  /* letter-spacing: 0; */
}
.showroom .address .phone-numbers .phone {
  display: inline-block;
  padding-right: 30px;
  border-right: 1px solid #ddd;
  margin-right: 30px;
  font-weight: bold;
  font-size: 13px;
  line-height: 26px;
  /* letter-spacing: 0; */
  text-transform: capitalize;
}
.showroom .address .phone-numbers .phone span {
  display: block;
  font-size: 12px;
  font-weight: bold;
  color: #999;
  line-height: 16px;
  /* letter-spacing: 0; */
  text-transform: capitalize;
}
.showroom .address .map-link {
  flex: 0 0 auto;
  text-align: right;
  /* letter-spacing: 0; */
}
.showroom h4 {
  /* letter-spacing: 0; */
  font-size: 16px;
  text-transform: capitalize;
}
.showroom .address .map-link a {
  padding: 3px 15px;
  line-height: 30px;
  display: inline-block;
  border-radius: 30px;
  font-size: 15px;
  /* letter-spacing: 0; */
  border: 1px solid #fc8f27;
}
.category-description {
  margin: 20px 0 30px;
}
.category-description h1 {
  font-size: 16px;
  /* letter-spacing: 0; */
  text-transform: capitalize;
}
.category-description h2 {
  font-size: 16px;
  /* letter-spacing: 0; */
  text-transform: capitalize;
}
.category-description p {
  font-size: 14px;
  /* letter-spacing: 0; */
  text-transform: capitalize;
}

/* Blog */
.bg-bt-gray {
  background: #f2f4f8;
  border-top: 1px solid #ddd;
}
.p-tb-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.article-thumb {
  margin-bottom: 30px;
}
.article-thumb .img-holder {
  position: relative;
}
.article-thumb .img-holder img {
  width: 100%;
}
.article-thumb .name {
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  color: #fc8f27;
  margin-bottom: 0;
  padding: 6px 15px;
  font-weight: bold;
  line-height: 24px;
  width: 100%;
  /* letter-spacing: 0; */
  text-transform: capitalize;
  font-size: 16px;
}
.article-thumb .blog-info {
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-weight: bold;
  background: #fff;
}
.article-thumb .blog-info .intro-text {
  margin: 5px 0 15px 0;
  height: 55px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* letter-spacing: 0; */
  font-size: 14px;
}

.pagination-wrapper {
  width: 100%; /* Ensure the pagination wrapper takes full container width */
  display: flex; /* Use flexbox for responsive layout */
  justify-content: center; /* Center pagination */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  margin: 0 auto; /* Ensure it's centered */
  justify-content: center;
  padding: 10px 0;
}

.pagination {
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.pagination li {
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: bold;
  line-height: 34px;
  text-align: center;
  min-width: 36px;
  text-decoration: none;
  transition: background-color 0.3s;
 
  
}
.pagination li a:hover {
  background-color: #fc8f27;
  color: #fff;
  transition: 0.3s;
}
.pagination li.active span {
  background-color: #fc8f27;
  color: #fff;
}
.pagination li span.disabled {
  color: #666;
  cursor: default;

}

.pagination li a,
.pagination li span {
  background: #ffffff;
  padding: 0 7px;
  color: #111;
  display: block;
  border-radius: 0;
  margin-left: unset;
}
.meta span i {
  margin-right: 6px;
  vertical-align: middle;
}
.meta .author {
  margin-right: 30px;
}
.color_btn_blog {
  background: #fc8f27 !important;
  display: inline-block !important;
  border: 2px solid #fc8f27 !important;
  padding: 0 20px !important;
  margin: 0 !important;
  height: 42px !important;
  line-height: 38px !important;
  font-size: 14px !important;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
  outline: none;
  text-align: center;
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  transition: all 300ms linear;
}
/* Blog Details page */
.bg-bt-gray {
  background: #f2f4f8;
  border-top: 1px solid #ddd;
}
.p-tb-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.blog-article .blog-left,
.blog-comments {
  background: #fff;
  padding: 15px;
}
.m-tb-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}
.meta .author {
  margin-right: 30px;
}
.blog-article .share-on {
  margin-top: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding-bottom: 10px;
  margin-bottom: 15px;
}
.blog-article .share-on .share {
  font-size: 20px;
  margin-right: 15px;
}
.blog-article .share-ico {
  font-size: 16px;
}
.icon-sprite.fb-dark {
  background-position: 0 -24px;
}
.icon-sprite {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url(https://www.startech.com.bd/catalog/view/theme/starship/images/xicon-sprite-v2.png.pagespeed.ic.4KZ1094RMo.png)
    no-repeat 0 0;
}
.icon-sprite {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url(https://www.startech.com.bd/catalog/view/theme/starship/images/xicon-sprite-v2.png.pagespeed.ic.4KZ1094RMo.png)
    no-repeat 0 0;
}
.blog-comments {
  margin-top: 30px;
}
.blog-article .blog-left,
.blog-comments {
  background: #fff;
  padding: 15px;
}
.article-popular-thumb {
  clear: both;
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.ppl-blog-list {
  background: #fff;
  padding: 15px;
}
.article-popular-thumb a img {
  float: left;
  margin-right: 15px;
}
.ppl-blog-info .title-name {
  font-weight: bold;
  margin-bottom: 5px;
  height: 36px;
  overflow: hidden;
}
.blog-article .blog-left,
.blog-comments {
  background: #fff;
  padding: 15px;
}
/* Online service support */
.complain-form {
  box-shadow: 10px 10px 14px 1px rgba(0, 0, 0, 0.18);
  border: 1px solid rgba(0, 0, 0, 0.18);
  margin-top: 30px;
  margin-bottom: 30px;
}
.form-complain {
  padding: 90px;
}
.complain-header h1 {
  color: #fc8f27;
  font-size: 30px;
  font-weight: bold;
  /* letter-spacing: 0; */
}
.complain-blurb p {
  font-size: 18px;
  color: #686868;
  font-weight: 500;
  /* letter-spacing: -0.5px; */
}
.form-complain .form-group {
  margin: 0 0 30px 0;
}
.form-complain .form-group label {
  font-size: 18px;
  font-weight: bold;
  color: #3a3a3a;
}
.form-complain .form-control {
  border: 0;
  border-radius: 0;
  border-bottom: 2px solid #b7b7b7;
  padding: 10px 10px 10px 0;
}
.form-complain .btn-complain {
  display: block;
  width: 100%;
  border: 0 !important;
  background-color: #fc8f27;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  padding: 10px;
}
.complain-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 50px 0 30px 0;
}
.complain-logo {
  width: 50%;
}
.complain-logo img {
  width: 40%;
}
.complain-social {
  width: 50%;
}
.complain-social ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content:end;
}
.complain-social ul li a {
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  display: block;
  color: #fff;
  margin: 0 2px;
  border-radius: 100%;
  font-size: 20px;
  font-weight: bold;
}
.form-complain .form-group label small {
  font-size: 18px;
  font-weight: bold;
  color: red;
}
.row.brand-row {
  padding-bottom: 70px;
}
.brand-row h1 {
  margin-top: 10px;
}
p.brand-index {
  display: flex;
}
p.brand-index strong {
  flex: 0 0 100px;
}
p.brand-index a {
  flex: 1 1 auto;
  text-align: center;
  margin-left: 5px;
  background: #fc8f271c;
  line-height: 30px;
  border-radius: 45px;
  color: #fc8f27;
}
.brand-row h2 {
  font-size: 60px;
  line-height: 70px;
  border-bottom: 1px solid #111;
  margin: 20px 0;
}
brand-row .col-sm-3 a {
  display: inline-block;
  color: #666;
  font-size: 16px;
  margin: 0 0 15px;
}
.brand-row h2 {
  font-size: 60px;
  line-height: 70px;
  border-bottom: 1px solid #111;
  margin: 20px 0;
}
@media (max-width: 767px) {
  p.brand-index {
    flex-wrap: wrap;
  }
}
@media (max-width: 1279px) {
  p {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .brand-row h2 {
    font-size: 50px;
  }
}
@media (max-width: 767px) {
  .brand-row h1 {
    margin-top: 0;
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .brand-row h1 {
    margin-top: 0;
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  p.brand-index a {
    min-width: 30px;
    margin: 0 5px 5px 0;
  }
}
@media (max-width: 767px) {
  p.brand-index strong {
    display: block;
    width: 100%;
    flex: 0 0 100%;
    padding-bottom: 10px;
  }
}
@media only screen and (max-width: 992px) {
  .form-complain {
    padding: 20px 20px !important;
  }
}
@media only screen and (max-width: 992px) {
  .complain-header h1 {
    font-size: 24px !important;
  }
}
@media only screen and (max-width: 992px) {
  .complain-blurb p {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 992px) {
  .complain-form {
    margin-top: 0;
    margin-bottom: 0;
    box-shadow: none;
    border: none;
  }
}
.list_style {
  list-style: none;
}
.bg-bt-gray h1 {
  /* letter-spacing: 0; */
  text-transform: capitalize;
}
.bg-bt-gray h2 {
  /* letter-spacing: 0; */
  text-transform: capitalize;
}
.bg-bt-gray h3 {
  /* letter-spacing: 0; */
  text-transform: capitalize;
}
.bg-bt-gray p {
  text-align: justify;
  text-transform: capitalize;
}
@media (max-width: 767px) {
  h1 {
    font-size: 20px;
    line-height: 28px;
    /* letter-spacing: 0; */
  }
}
@media (max-width: 767px) {
  h2 {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 2px;
    /* letter-spacing: 0; */
  }
}
@media (max-width: 1279px) {
  p {
    font-size: 14px;
    /* letter-spacing: 0; */
  }
}
.ppl-btn {
  color: #fc8f27;
  font-size: 14px;
  font-weight: 500;

  display: block;
  /* text-align: center; */
}
@media (max-width: 767px) {
  .ppl-btn {
    text-align: left;
  }
}
.article-popular-thumb {
  clear: both;
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.article-popular-thumb a img {
  float: left;
  margin-right: 15px;
}
.ppl-blog-info .title-name {
  font-weight: bold;
  margin-bottom: 5px;
  height: 36px;
  overflow: hidden;
  /* letter-spacing: 0; */
}
.width_change {
  width: 50% !important;
}

@media (max-width: 575.98px) {
  .width_change {
    width: 100% !important;
  }
}
.witdth_btn {
  width: 25% !important;
}

@media (max-width: 575.98px) {
  .witdth_btn {
    width: 100% !important;
  }
}
/* ask question */
.panel {
  margin-bottom: 50px;
}
@media screen and (max-width: 1279px) {
  .panel {
    overflow: auto;
  }
}
@media (max-width: 1024px) {
  .ac-layout .ac-title {
    padding-top: 10px;
  }
}
.ac-layout .ac-title {
  display: flex;
  line-height: 30px;
  padding: 0 0 20px;
}
.ac-layout form .btn {
  margin-top: 10px;
}
.ac-layout form .btn {
  margin-top: 10px;
}
.panel .btn {
  width: 100%;
  text-align: center;
}
.margin_top {
  margin-top: 1px;
}
.card_custom:hover {
  width: 100%;
  color: #fc8f27;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  transition: all 0.2s ease-out;
}
.full_nav {
  position: fixed;
  top: 100px;
  right: 0;
  left: 0;
  z-index: 1030;
  margin-bottom: 30px;
  transition: all 0.1s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  /* background-color: #000; */
  background: radial-gradient(
    circle at 10% 20%,
    rgb(228, 118, 0) 0%,
    rgb(247, 189, 2) 90%
  );
}
.nav_bb .dropdown-item:hover {
  background-color: #fc8f27 !important;
  /* color: #fff !important; */
}
.sub_navLink .dropdown-item {
  height: 40px;
  color: #000 !important;
  background: #fff;
  font-weight: bold;
  font-size: 13px;
  width: 200px;

padding-top: 10px;
display: flex;
justify-content: left;
align-items: center;
}

@media (max-width: 575.98px) {.sub_navLink .dropdown-item {
padding-left: 35px;

}
}
.sub_navLink .dropdown-item:hover {
  background: radial-gradient(
    circle at 10% 20%,
    rgb(228, 118, 0) 0%,
    rgb(247, 189, 2) 90%
  ) !important;
  color: #fff !important;
}
.nav_serch_btn i {
  color: #fff;
  font-size: 20px;
}

.font_customize {
  font-size: 70% !important;
}
.navbar-dark .navbar-nav .nav-link {
  color: #000 !important;
  border: 1px solid #f18d17;
  border-radius: 4px;
  font-weight: bold;

  text-transform: capitalize;
  font-size: 16px;
  line-height: 1;
}
@media (max-width: 575.98px) {
  .navbar-dark .navbar-nav .nav-link {
    padding: 10px 20px;
  }
}
.admin_ico {
  color: #000;
  font-size: 18px;
}
.card_helpline h6 {
  color: #000;
  margin-top: 0.5rem;
  /* letter-spacing: 0; */
  line-height: 1;
  font-size: 16px;
}
.sub_navLink {
  position: absolute !important;
  z-index: 9999;
  background-color: transparent;
  /* color: #fff; */
  padding-top: 8px;
  /* margin-top: 8px; */
  
  /* top: 40px; */

}

.dropdown-item {
  position: relative;
  color: #fff;
  font-weight: bold;
  text-transform: capitalize;
  font-size: 13px;
  padding: 0.25rem 1rem;
  margin-bottom: -1px;
}
.card_helpline {
  background-color: #fc8f27 !important;
  /* box-shadow: 0 1px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
  color: #000;
  border-radius: 4px;
  border: unset;
  line-height: 0.4;
}
.icon_fott {
  background-color: #3a4856;
  border-radius: 50%;
  padding: 10px;
  color: #fff;
}
.card_head_custom img {
  height: 100%;
}
.margin-button-card {
  margin: 10px 0 !important;
}
.logo-width {
  width: 250px !important;
}
@media (max-width: 575.98px) { .logo-width {
  width: 170px !important;
} }
.offer {
  position: absolute;
  background-color: #fc8f27;
  color: #fff;
  padding: 0px 20px;
  border-radius: 0px 6px 6px 0px;
  top: 10%;
  left: 0;
}
.product_card {
  border: unset;
  border-radius: 5px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-out;

  margin: 10px 0;
  background-color: #fff;
}
.nav_btn {
  /* background: linear-gradient(45deg,#0f5828,#fc8f27,#0bc1e9,#fc8f27,#fc8f27); */
  /* background-image: linear-gradient(to right top, #f39220, #f28f1c, #f18d17, #f08a11, #ef870b) !important; */
  background-size: 400% 400%;
  background: radial-gradient(
    circle at 10% 20%,
    rgb(228, 118, 0) 0%,
    rgb(247, 189, 2) 90%
  );
  animation: gradient 15s ease infinite;
  border: none;

  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 4px;
  outline: none;
  text-align: center;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.bg_colorAdd {
  background-color: #dec0b2;
}
.pcbuilder_containar {
  border: 1px solid #c3c8eb;
  max-width: 950px;
  margin: 0 auto;
  background: #fff;
}
.pcbuilder_header {
  background: #f9f9fc;
  border-bottom: 1px solid #c3c8eb;
  padding: 20px;
  display: flex;
}
.pcbuilder_header .site_logo {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
}
.pcbuilder_header .site_logo img {
  height: 43px;
  margin-left: 10px;
}
.pcbuilder_header .pcbaction {
  flex: 1 1 auto;
  padding-top: 0;
}
.pcbuilder_header .pcbaction .pcbuilder_actions {
  display: flex;
  float: right;
}
.pcbuilder_header .pcbaction .action {
  display: inline-block;
  text-align: center;
  padding: 0 15px;
  background: none;
  border: none;
  color: #ef4a23;
  height: auto;
  line-height: normal;
  font-weight: normal;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  outline: none;
}
.pcbuilder_header .action .pcbuilder_text {
  display: block;
  white-space: nowrap;
  font-size: 12px;
  color: #666;
  padding-top: 2px;
  min-width: 40px;
}
.pcb-inner-content {
  margin: 25px auto;
  max-width: 800px;
}
.pcb-top-content {
  display: flex;
  margin: 0 0 30px;
}
.pcb-top-content > div {
  flex: 1 1 50%;
}
.pcb-top-content h1 {
  font-size: 15px;
  font-weight: bold;
  line-height: 0px;
  color: #3749bb;
  margin: 0px 0;
}
.pcb-top-content .checkbox-inline {
  color: #808996;
  font-size: 13px;
}
.checkbox-inline input {
  margin-right: 5px;
  position: relative;
  top: 3px;
}
.pcb-top-content .right {
  text-align: right;
}
.pcb-top-content .total-amount {
  display: inline-block;
  border: 1px dashed #ef4a23;
  padding: 8px 0;
  margin-left: 10px;
  min-width: 140px;
  border-radius: 7px;
  text-align: center;
  color: #808996;
  position: relative;
}
.pcb-top-content .total-amount .amount {
  font-size: 18px;
  color: #111;
  padding-bottom: 3px;
}
.pcb-top-content .total-amount .items {
  font-size: 11px;
  font-weight: bold;
}
.pcb-top-content .total-amount.t-price {
  border: 1px solid #3749bb;
  color: #fff;
  background: #3749bb;
}
.pcb-top-content .total-amount.t-price .amount {
  color: #fff;
}
.pcb-top-content .total-amount .items {
  font-size: 11px;
  font-weight: bold;
}
.pcb-top-content .total-amount .amount {
  font-size: 18px;
  color: #111;
  padding-bottom: 3px;
}
.pcb-top-content .total-amount .items {
  font-size: 11px;
  font-weight: bold;
}
.betaa {
  top: 5px;
  right: 8px;
  position: absolute;
  color: #3749bb;
  font-weight: bolder;
  font-size: 10px;
}
.pcbuilder_cont .content-label {
  background: #808996;
  color: #fff;
  line-height: 20px;
  padding: 0 10px;
  font-size: 12px;
  font-weight: bold;
}
.pcbuilder_cont .pcuilder_items {
  display: flex;
  padding: 15px 10px;
  flex: 0 0 auto;
  border-bottom: 1px solid #eee;
}
.pcbuilder_cont .pcuilder_items .img {
  background: var(--s-s-bg);
  border-radius: 3px;
  overflow: hidden;
  width: 60px;
  height: 60px;
  text-align: center;
  flex: 0 0 60px;
}

.pcbuilder_cont .pcuilder_items .img-ico {
  background: url(https://www.startech.com.bd/catalog/view/theme/starship/images/cpu.svg)
    no-repeat center;
  width: 60px;
  height: 60px;
  display: block;
}
.pcbuilder_cont .pcuilder_items .details {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px 0 20px;
}
.pcuilder_items.blank .details .component-name {
  color: #666;
}
.pcuilder_items .details .component-name .mark {
  margin-left: 5px;
  background: #808996;
  color: #fff;
  padding: 1px 4px;
  border-radius: 2px;
  font-weight: normal;
}
.pcuilder_items.blank .details .pcbuilder_prod_name {
  background: #f2f3f4;
  min-height: 12px;
  max-width: 80%;
  margin-top: 4px;
}
.pcuilder_items .item-price {
  flex: 0 0 100px;
  margin: 10px 0;
  padding-right: 20px;
  display: flex;
  align-items: center;
  border-right: 1px solid #eee;
}
.pcuilder_items.blank .item-price:after {
  content: "";
  display: block;
  min-height: 12px;
  background: #f2f3f4;
  width: 100%;
}
.pcuilder_items .actions {
  flex: 0 0 110px;
  text-align: right;
  padding-top: 8px;
}
.pcbuilder_btn {
  background: none;
  color: #3749bb;
}
.pcbuilder_cont .content-label {
  background: #808996;
  color: #fff;
  line-height: 20px;
  padding: 0 10px;
  font-size: 12px;
  font-weight: bold;
}

.p-item-page #column-left {
  -ms-flex: 0 0 280px;
  flex: 0 0 280px;
  max-width: 280px;
  padding-right: 0;
  margin-right: 0;
}
.filters {
  background: var(--s-b-bg);
  border-radius: 7px;
}
.price-filter {
  display: inline-block;
  width: 100%;
  padding: 0 20px 20px;
  height: 131px;
}
.price-filter .label {
  margin: 0 -20px 30px;
}
.filters .label span {
  float: left;
  display: inline-block;
}
.pf-wrap {
  height: 40px;
  overflow: hidden;
}
.price-filter .noUi-horizontal {
  width: calc(100% - 20px);
  margin-top: 10px;
}
.price-filter .noUi-base {
  border-radius: 0;
  border: none;
  box-shadow: none;
  background: #eee;
}
.noUi-connects {
  overflow: hidden;
  border-radius: 3px;
  z-index: 0;
}
.product-thumb {
  position: relative;
  display: flex;
  margin-bottom: 10px;
}

@media (max-width: 767.98px) {
  .product-thumb {
    position: relative;
    display: inline;
    margin-bottom: 10px;
  }
}
.ws-box {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.product-thumb .img-holder {
  flex: 0 0 200px;
  padding: 10px;
  margin: 0;
  text-align: center;
}
.product-thumb .product-info {
  padding: 20px;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
}
.product-thumb .product-content-blcok {
  flex: 1 1 auto;
}
.product-thumb .product-info h4 {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
  position: relative;
  height: auto;
}
.product-thumb .product-name a {
  color: #111;
  /* letter-spacing: 1px; */
}
.product-thumb .short-description {
  padding: 0 0 0 14px;
  flex: 1 1 auto;
  margin-bottom: 5px;
}
.product-thumb .short-description li {
  font-size: 13px;
  padding: 5px 0;
  color: #666;
  position: relative;
  line-height: 16px;
}
.product-thumb .actions {
  flex: 0 0 95px;
}
.product-thumb .actions .price {
  font-size: 20px;
  text-align: center;
}
.product-thumb .btn {
  display: block;
  min-width: 100px;
  text-align: center;
  margin-top: 15px;
}
.btn-add {
  background: #fc8f27;
  display: inline-block;
  border: 2px solid #fc8f27;
  padding: 0 20px;
  margin: 0;
  height: 42px;
  line-height: 38px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
  outline: none;
  text-align: center;
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  transition: all 300ms linear;
}
filter-group {
  position: relative;
  user-select: none;
  clear: both;
  padding: 0;
  margin-top: 5px;
}
.ws-box {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.price-filter .label,
.filter-group .label {
  padding: 0 0 0 20px;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
  color: #111;
  font-size: 17px;
  border-bottom: 1px solid #eee;
}
.filters .show .label:after {
  transform: rotate(180deg);
}
.filter-group .label:after {
  /* content: "\f077"; */
  font-size: 20px;
  height: 100%;
  width: 24px;
  float: right;
  position: relative;
  right: 10px;
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  transition: all 300ms linear;
}
.filter-group.show .items {
  display: block;
}
.filter-group .items {
  display: none;
  padding: 10px 20px 10px;
  margin-right: 0;
  max-height: 300px;
  overflow-y: auto;
}
.filter-group .items label.filter {
  display: inline-block;
  width: 100%;
  padding: 6px;
  margin: 0 -6px;
  font-size: 14px;
  border-radius: 3px;
  cursor: pointer;
}
.filter-group .items label.filter input {
  margin-right: 10px;
  height: 16px;
  width: 16px;
  position: relative;
  top: 2px;
  float: left;
}
.filter-group .items label.filter span {
  display: block;
  margin-left: 25px;
  line-height: 20px;
  color: #111;
}
.top-bar {
  padding: 10px 10px 10px 20px;
  margin-bottom: 10px;
}
.tool-btn {
  display: flex;
  margin: 0 0 -40px 0;
  color: #000;
  padding: 0 10px;
  height: 41px;
  background: rgba(55, 73, 187, 0.05);
  border: none;
  white-space: nowrap;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  outline: none;
}
.top-bar .search {
  background: #f1f3f5;
  border-radius: 3px;
  position: relative;
  padding: 1px;
  display: flex;
}
.top-bar .search input {
  float: left;
  /* height: 28px; */
  border: none;
  padding: 0 10px;
  background: none;
  outline: none;
}
.top-bar .search i {
  line-height: 30px;
  font-size: 20px;
  cursor: pointer;
}
.show-sort .form-group {
  display: inline-block;
  padding-left: 10px;
  margin-bottom: 0;
}
.show-sort .form-group label {
  padding-right: 5px;
  color: #666;
}
.show-sort .form-group select {
  background: #f1f3f5;
  padding: 6px 5px;
  font-size: 14px;
  border: none;
  position: relative;
  outline: none;
  height: 30px;
  max-width: 110px;
}
.show-sort .form-group * {
  display: inline-block;
}
select option {
  font-size: 15px;
  line-height: 30px;
}
.show-sort .form-group select {
  background: #f1f3f5;
  padding: 6px 5px;
  font-size: 14px;
  border: none;
  position: relative;
  outline: none;
  height: 30px;
  max-width: 110px;
}
.order_his {
  border-radius: 4px;
}
.item_nav_color {
  color: #fff !important;
}
.carousel-container {
  height: auto !important; /* Adjust this height as needed */
}
/* .carousel{
  display: none;
} */
.thumbs-wrapper-box{
  border: 1px solid #e57b00;
  margin: 5px;
  top: -100px;
  left: 50px;

}
.for_padding  .thumbs-wrapper {
  margin: 0px !important;
  overflow: hidden;
  padding: 0 49px !important;
  margin-bottom: 1rem !important;
}
 .thumbs-wrapper {
  margin: 0px !important;
  overflow: hidden;
  padding: 0 125px !important;
  margin-bottom: 1rem !important;
}
@media (max-width: 575.98px) { 
  .thumbs-wrapper {
    margin: 0px !important;
    overflow: hidden;
    padding: 0 25px !important;
    margin-bottom: 1rem !important;
  }
 }
.carousel .control-dots .dot {
  transition: opacity .25s ease-in;
  opacity: .3;
  filter: alpha(opacity = 30);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
  background: #FF9800 !important;
  border-radius: 50%;
  width: 12px !important;
  height: 12px !important;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px;
}
.edit_specification{

  margin-bottom: 8px;
  height: 45px;
  display: flex;
  justify-content: end;
  margin-top: 29px;
  align-items: center;
}
.edit_specification_value{

  margin-bottom: 8px;
  height: 45px;
  display: flex;
  justify-content: end;
  margin-top: 45px;
  align-items: center;
}
.specification_card{
  background-color: #f7f7f9;
  border: 1px solid #ced4da;
  border-radius: 6px;
  padding: 1rem 2rem;
  margin-top: 1rem;
}
.jj{border: 1px solid #fff;
padding: 1rem 2rem;
box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
background: #fff;
}
.last-reset span{
  font-size: 12px;
}